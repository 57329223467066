/* CSS */
import "@css/app.pcss";
// import Swiper bundle with all modules installed
import Swiper from 'swiper/bundle';
// import styles bundle
import 'swiper/css/bundle';

/* JS */
import 'lazysizes';
import { gsap } from "gsap";    
import { CustomEase } from "gsap/CustomEase";
import { RoughEase, ExpoScaleEase, SlowMo } from "gsap/EasePack";    
import { Flip } from "gsap/Flip";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { Draggable } from "gsap/Draggable";
import { MotionPathPlugin } from "gsap/MotionPathPlugin";
import { TextPlugin } from "gsap/TextPlugin";
/* The following plugins are Club GSAP perks */
import { DrawSVGPlugin } from "gsap/DrawSVGPlugin";
import { ScrambleTextPlugin } from "gsap/ScrambleTextPlugin";
import { SplitText } from "gsap/SplitText";
//import * as YTPlayer from 'yt-player';


/* SCRIPTS HERE */

document.addEventListener("DOMContentLoaded", () => {
    // Function to replace apostrophe with typographic single quote
    function replaceText(elements) {
        // Loop through all elements with the class 'header'
        elements.forEach(element => {
            // Replace all instances of apostrophe with typographic single quote
            element.innerHTML = element.innerHTML.replace(/CC's/g, '<span class="normal-case">CC’s</span>');
            element.innerHTML = element.innerHTML.replace(/®/g, '<span class="superscript">®</span>');
            element.innerHTML = element.innerHTML.replace(/™/g, '<span class="superscript">™</span>');
        });
    }

    // Get all elements with the class 'header'
    const headerElements = document.querySelectorAll('.heading');
    const nodeElements = document.querySelectorAll('.node');
    const repeaterElements = document.querySelectorAll('.repeater-list-card-header');

    // Call the function with the elements
    replaceText(headerElements);
    replaceText(nodeElements);
    replaceText(repeaterElements);
});

/**
 * Alpine JS
 * Best to put it last so that all other potential JS is available
 * when components start getting initialized.
 */
import Alpine from 'alpinejs';
import collapse from '@alpinejs/collapse';
import focus from '@alpinejs/focus';
import persist from '@alpinejs/persist';

var duration = duration || 1;
gsap.registerPlugin(Flip,ScrollTrigger,ScrollToPlugin,Draggable,MotionPathPlugin,TextPlugin,DrawSVGPlugin,ScrambleTextPlugin,SplitText,RoughEase,ExpoScaleEase,SlowMo,CustomEase);    

document.addEventListener('alpine:init', () => {

  // Your custom callback function
  function mapPan(event) {
      var markerId = "#{loc.id}-storeAddress";
      map.panToMarker(markerId);
      console.log("Clicked!");
  }

  // Magic: $tooltip
  Alpine.magic('tooltip', el => message => {
      let instance = tippy(el, { content: message, trigger: 'manual' })

      instance.show()

      setTimeout(() => {
          instance.hide()

          setTimeout(() => instance.destroy(), 150)
      }, 2000)
  })

  // Directive: x-tooltip
  Alpine.directive('tooltip', (el, { expression }) => {
      tippy(el, { content: expression })
  })

  Alpine.data('sectionStandard', (animateContent, staggerAnimations) => ({
    init() {       
      if (animateContent) {
        const animParent = this.$refs.animparent;
        const animSelector = "#" + animParent.getAttribute('id') + " .animate" ;
        const animCast = document.querySelectorAll(animSelector);        
        if (staggerAnimations) {          
          ScrollTrigger.batch(animCast, {
            onEnter: batch => gsap.from(batch, 
            {
              duration: duration * 1,		
              y: 35,
              opacity: 0,
              // Note: Easing Pack required for easing
              ease: "back.inOut",
              onComplete() {
                this.postRevealFunction;
              },
              stagger: 0.1			
            }),
            start: "top 95%",
            once: true
          });         
        } else {
          animCast.forEach(animItem => {              
            gsap.from(animItem, { 			
              duration: duration * 1,
              y: 35,			
              opacity: 0,              			
              // Note: Easing Pack required for easing
              ease: "back.inOut",
              onComplete: this.postRevealFunction,              	
              scrollTrigger: {
                trigger: animParent,
                start: "top 95%",
              }			
            })
          });
        }
      } else {
        const animElem = this.$refs.animate;     
        gsap.from(animElem, {
          duration: duration * 1,
          y: 35,          
          opacity: 0,			
          // Note: Easing Pack required for easing
          ease: "back.inOut",
          onComplete: this.postReveal,          			
          scrollTrigger: {
            trigger: animElem,
            start: "top 95%",
          }
        })
      }      
    },
        
    postReveal() {
      
    }    
      
  })),
  
  Alpine.data('sectionSidePhoto', (animateContent, staggerAnimations) => ({
    init() {       
      if (animateContent) {
        const animParent = this.$refs.animparent;
        const animSelector = "#" + animParent.getAttribute('id') + " .animate" ;
        const animCast = document.querySelectorAll(animSelector);        
        if (staggerAnimations) {          
          ScrollTrigger.batch(animCast, {
            onEnter: batch => gsap.from(batch, 
            {
              duration: duration * 1,	              
              opacity: 0,
              // Note: Easing Pack required for easing
              ease: "expo.out",
              onComplete() {
                this.postRevealFunction;
              },
              stagger: 0.1			
            }),
            start: "top 95%",
            once: true
          });         
        } else {
          animCast.forEach(animItem => {              
            gsap.from(animItem, { 			
              duration: duration * 1,              			
              opacity: 0,              			
              // Note: Easing Pack required for easing
              ease: "expo.out",
              onComplete: this.postRevealFunction,              	
              scrollTrigger: {
                trigger: animParent,
                start: "top 95%",
              }			
            })
          });
        }
      } else {
        const animElem = this.$refs.animate;     
        gsap.from(animElem, {
          duration: duration * 1,          
          force3D: true,
          opacity: 0,			
          // Note: Easing Pack required for easing
          ease: "expo.out",
          onComplete: this.postReveal,          			
          scrollTrigger: {
            trigger: animElem,
            start: "top 95%",
          }
        })
      }      
    },
        
    postReveal() {
      
    }    
      
  }))
  
 
    
})

if (typeof window.Alpine === "undefined") {
  Alpine.plugin(collapse);
  Alpine.plugin(focus);
  Alpine.plugin(persist);
  window.Alpine = Alpine;
  window.Swiper = Swiper;  
  Alpine.start();
}


// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
if (import.meta.hot) {
  import.meta.hot.accept(() => {
	console.log("HMR")
  });
}

